import { Component } from '@angular/core';

@Component({
  selector: 'app-password-forgotten',
  standalone: true,
  imports: [],
  templateUrl: './password-forgotten.component.html',
  styleUrl: './password-forgotten.component.scss'
})
export class PasswordForgottenComponent {

}
