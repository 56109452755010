import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../Services/BuissnessLogic/auth.service';
import {ColorScssService} from '../../shared/service/color-scss.service';
import {InteliRouteService} from '../../Services/inteli-route.service';
import {SeoService} from '../../Services/SEO/seo.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  registerForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private color: ColorScssService,
              private intRoute: InteliRouteService,
              private seoService: SeoService,
            ) {
    this.seoService.setMetaData("register");
    color.resetColorScheme()
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_repeat: ['', [Validators.required]],
    }, {
      validator: this.matchPassword('password', 'password_repeat')
    });
  }

  ngOnInit(): void {
  }

  // Method to handle form submission
  onSubmit(): void {
    if (!this.registerForm.valid) {
      console.log("invalid Register Form");
      return;
    }

    const username = this.registerForm.get('username')?.value;
    const email = this.registerForm.get('email')?.value;
    const password = this.registerForm.get('password')?.value;

    this.authService.register(username, email, password).subscribe({
      next: response => {
        if (this.authService.isLoggedIn()) {
          this.router.navigate(['/profil'])
          if (this.intRoute.is_valid()) {
            this.intRoute.navigateToStoredRoute();
            return;
          }
        }
      },
      error: error => {
        if (error.status === 402) {
          console.log("Email schon verwendet");
        } else {
          console.error('Error:', error);
        }
      }
    });
  }
  matchPassword(password: string, password_repeat: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const passwordRepeatControl = formGroup.controls[password_repeat];

      if (passwordRepeatControl.errors && !passwordRepeatControl.errors['mustMatch']) {
        // Return if another validator has already found an error on the password_repeat field
        return;
      }

      // Set error on password_repeat if the passwords don't match
      if (passwordControl.value !== passwordRepeatControl.value) {
        passwordRepeatControl.setErrors({ mustMatch: true });
      } else {
        passwordRepeatControl.setErrors(null);
      }
    };
  }
}
