export class EnterpriceSassWorkConfig {
    public static readonly NAV_ITEMS = [
        { img: 'assets/images/saas1/tab-icon/installation.png', title: 'Branche Auswählen' },
        { img: 'assets/images/saas1/tab-icon/001-tap.png', title: 'Region Auswählen' },
        { img: 'assets/images/saas1/tab-icon/button.png', title: 'Spalten Bearbeiten' },
        { img: 'assets/images/saas1/tab-icon/002-settings.png', title: 'Zeilen Bearbeiten' },
        { img: 'assets/images/saas1/tab-icon/key-1.png', title: 'Bestellen' }
    ];

    public static readonly PRICING_CAROUSEL_OPTIONS = {
        items: 3,
        margin: 30,
        nav: false,
        autoplay: false,
        slideSpeed: 300,
        loop: false,
        mouseDrag: false,
        touchDrag: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
                margin: 10
            },
            576: {
                items: 2,
                margin: 10
            },
            991: {
                items: 2,
                margin: 15
            },
            992: {
                items: 3,
                margin: 15
            },
            1000: {
                items: 3
            }
        }
    };

    public static readonly PRICING = [
        {
            icon: 'rocket-ship',
            package: 'Basic',
            feature1: 'Firmen Name',
            feature2: 'Website',
            feature3: 'Telefon Nummer',
            feature4: 'Adresse',
            feature5: 'Postleizahl',
            feature6: '-',
            feature7: '-',
            price: 0.01,
            btn: 'purchase',
            id: 0
        },
        {
            icon: 'diamond',
            package: 'Medium',
            feature1: 'Firmen Name',
            feature2: 'Website',
            feature3: 'Telefon Nummer',
            feature4: 'Adresse',
            feature5: 'Postleizahl',
            feature6: 'Email Adressen',
            feature7: '-',
            price: 0.05,
            btn: 'purchase',
            id: 1
        },
        {
            icon: 'pie-chart',
            package: 'Business',
            feature1: 'Firmen Name',
            feature2: 'Website',
            feature3: 'Telefon Nummer',
            feature4: 'Adresse',
            feature5: 'Postleizahl',
            feature6: 'Email Adressen',
            feature7: 'Name Geschäftsführer',
            price: 0.10,
            btn: 'purchase',
            id: 2
        }
    ];

    public static readonly SUPPORTED_REGIONS = [
        'Deutschlandweit',
        'Baden-Württemberg',
        'Bayern',
        'Berlin',
        'Brandenburg',
        'Bremen',
        'Hamburg',
        'Hessen',
        'Mecklenburg-Vorpommern',
        'Niedersachsen',
        'Nordrhein-Westfalen',
        'Rheinland-Pfalz',
        'Saarland',
        'Sachsen',
        'Sachsen-Anhalt',
        'Schleswig-Holstein',
        'Thüringen'
    ];
}
