import {Injectable} from '@angular/core';
import {environment} from '../../environments/environments';

@Injectable({
    providedIn: 'root'
})
export class PaypalService {
    initializePayPal(buttonContainerId: string, amount: number, userId: number, tableName: string, onTransactionComplete: (details: any) => void): void {
        (window as any).paypal.Buttons({
            createOrder: (data: any, actions: any) => {
                return actions.order.create({
                    purchase_units: [
                        {
                            amount: {
                                value: amount.toString(),
                            },
                        },
                    ],
                });
            },
            onApprove: (data: any, actions: any) => {
                // Example user_id and table_name values
                return fetch(`${environment.apiUrl}/capture-order`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        orderID: data.orderID,
                        user_id: userId.toString(),
                        table_name: tableName,
                    }),
                })
                    .then(response => response.json())
                    .then(details => {
                        if (details.status === 'success') {
                            onTransactionComplete(details);
                        } else {
                            alert('Transaction failed: ' + details.message);
                        }
                    })
                    .catch(err => {
                        console.error('Error capturing order:', err);
                    });
            },
            onError: (err: any) => {
                console.error('PayPal Checkout error:', err);
            },
        }).render(`#${buttonContainerId}`);
    }
}
