<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain ">
                    <div>
                        <h2 class="breadcrumb-txt">Anmelden</h2>
                    </div>
                    <div>
                        <ul>
                            <li><a href="#">Login</a></li>
                            <li><a href="#"><i class="fa fa-angle-double-right"></i>Anmelden</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>Anmelden</h3>
                <div class="theme-card">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="theme-form">
                        <div class="form-group">
                            <label for="email">Email / Nutzername</label>
                            <input type="text" class="form-control" id="email" placeholder="Email"
                                   formControlName="email" required="">
                            <div *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched"
                                 class="error-text error-required-field">
                                <span *ngIf="loginForm.get('email')?.errors?.['required']">E-Mail oder Nutzername wird benötigt.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password">Passwort</label>
                            <input type="password" class="form-control" id="password" placeholder="Passwort"
                                   formControlName="password" required="">
                            <div *ngIf="loginForm.get('password')?.errors?.['required'] && loginForm.get('password')?.invalid && loginForm.get('password')?.touched"
                                    class="error-text error-required-field">
                              <span >
                                Passwort wird benötigt.
                              </span>
                                <a href="/passwort-vergessen" class="passw-forgotten">
                                    Passwort Vergessen?
                                </a>
                            </div>
                        </div>
                        <button type="submit" class="btn primary-btn btn-default radius-0">Anmelden</button>
                        <div *ngIf="password_error" class="error-text-login-error">
                            <span *ngIf="password_error">Login Fehler.</span>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 right-login">
                <h3>Neuer Kunde</h3>
                <div class="theme-card authentication-right">
                    <h6 class="title-font">Erstelle einen Account</h6>
                    <p>Melden Sie sich für ein kostenloses Konto in unserem Shop an. Die Registrierung ist schnell und
                        einfach. Sie ermöglicht es Ihnen, in unserem Shop zu bestellen. Klicken Sie auf "Registrieren",
                        um mit dem Einkaufen zu beginnen.</p>
                    <a class="btn primary-btn btn-default radius-0" [routerLink]="['/registrieren']">Account
                        erstellen</a>
                </div>
            </div>
        </div>
    </div>
</section>
