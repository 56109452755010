export const SEO_DATA = {
    homepage: {
        title: 'Emails Deutscher Unternehmen - Palladio',
        description: 'Hochwertige B2B-Leads und umfassende Geschäftsdatenlösungen für den deutschen Markt. Steigern Sie Ihre Akquise mit unserer Hilfe.',
        keywords: 'B2B-Leads, Geschäftsdaten Deutschland, Lead-Generierung, Palladio, Email Adressen deutscher Unternehmen',
        ogTitle: 'Palladio - Hochwertige B2B-Leads in Deutschland',
        ogDescription: 'Optimieren Sie Ihre Lead-Akquise mit deutschen Geschäftsdaten von Palladio.'
    },
    scrollViewPage: {
        title: 'B2B-Email-Adressen und Daten-Tabellen durchsuchen und kaufen - Palladio',
        description: 'Finden Sie gezielte B2B-Email-Adressen und umfangreiche Daten-Tabellen, um Ihre Lead-Generierung in Deutschland zu optimieren. Kaufen Sie hochwertige Datensätze für Ihren Geschäftserfolg.',
        keywords: 'B2B-Email-Adressen, Email-Adressen kaufen, B2B-Daten, Lead-Generierung, deutsche Unternehmen, B2B-Daten-Tabellen',
        ogTitle: 'B2B-Email-Adressen und Daten-Tabellen - Palladio',
        ogDescription: 'Durchsuchen und kaufen Sie hochwertige B2B-Email-Adressen und gezielte Daten-Tabellen für die Geschäftsentwicklung und Lead-Generierung in Deutschland.'
    },
    generateTablePage: {
        title: 'B2B-Email Tabellen erstellen - Palladio',
        description: 'Erstellen Sie individuelle Tabellen mit B2B-Email-Adressen von Unternehmen in Deutschland und optimieren Sie Ihre Lead-Generierung.',
        keywords: 'B2B-Email-Tabellen, Lead-Generierung, deutsche Unternehmen, B2B-Daten, Email-Adressen',
        ogTitle: 'B2B-Email Tabellen erstellen - Palladio',
        ogDescription: 'Erstellen Sie maßgeschneiderte Tabellen mit B2B-Email-Adressen für gezielte Lead-Generierung in Deutschland mit Palladio.'
    },
    kontaktPage: {
        title: 'Kontaktieren Sie Uns - Palladio',
        description: 'Nehmen Sie Kontakt mit Palladio auf und erfahren Sie mehr über unsere Dienstleistungen für die B2B-Lead-Generierung. Finden Sie Antworten auf häufig gestellte Fragen in unserem FAQ-Bereich.',
        keywords: 'Kontakt, Palladio, B2B-Lead-Service, deutsche Geschäftsdaten, FAQ Palladio',
        ogTitle: 'Kontakt - Palladio',
        ogDescription: 'Kontaktieren Sie Palladio, um mehr über unsere B2B-Lösungen in Deutschland zu erfahren. Besuchen Sie auch unseren FAQ-Bereich für weitere Informationen.'
    },
    impressumsPage: {
        title: 'Impressum - Palladio',
        description: 'Lesen Sie das Impressum von Palladio und erfahren Sie mehr über unser Unternehmen und die rechtlichen Informationen.',
        keywords: 'Impressum, Palladio, rechtliche Hinweise, Unternehmensinformationen, B2B-Lead-Generierung',
        ogTitle: 'Impressum - Palladio',
        ogDescription: 'Erfahren Sie mehr über Palladio, unsere rechtlichen Informationen und unser Engagement für die B2B-Lead-Generierung in Deutschland.'
    },
    PageNotFound: {
        title: 'Seite nicht gefunden - Palladio',
        description: 'Die angeforderte Seite wurde leider nicht gefunden. Überprüfen Sie die URL oder gehen Sie zurück zur Startseite.',
        keywords: '404, Seite nicht gefunden, Palladio, Fehler, URL',
        ogTitle: '404 - Seite nicht gefunden - Palladio',
        ogDescrieption: 'Entschuldigung, die Seite, die Sie suchen, konnte nicht gefunden werden. Besuchen Sie unsere Startseite für weitere Informationen.'
    },
    services: {
        title: 'Unsere Dienstleistungen - Palladio',
        description: 'Erfahren Sie mehr über unsere Dienstleistungen zur B2B-Lead-Generierung und lesen Sie Kundenmeinungen.',
        keywords: 'Dienstleistungen, Palladio, B2B-Leads, Geschäftsentwicklung, Kundenmeinungen',
        ogTitle: 'Unsere Dienstleistungen - Palladio',
        ogDescription: 'Entdecken Sie unsere B2B-Dienstleistungen und erfahren Sie, wie Kunden von Palladio profitieren.',
    },
    login: {
        title: 'Anmelden - Palladio B2B-Leads Plattform',
        description: 'Melden Sie sich bei Ihrem Palladio-Konto an, um auf hochwertige B2B-Leads und Geschäftsdaten zuzugreifen. Starten Sie jetzt mit Ihrer Akquise!',
        keywords: 'Palladio Anmeldung, B2B-Leads Login, Geschäftsdaten Deutschland, Lead-Generierung Zugang, Kundenkonto Palladio',
        ogTitle: 'Anmelden - Palladio B2B-Leads Plattform',
        ogDescription: 'Loggen Sie sich in Ihr Palladio-Konto ein und greifen Sie auf deutsche Geschäftsdaten und Leads zu, um Ihre Akquise zu optimieren.'
    },
    register: {
        title: 'Registrieren - Werden Sie Teil von Palladio',
        description: 'Erstellen Sie ein Palladio-Konto und starten Sie mit hochwertigen B2B-Leads und deutschen Geschäftsdaten. Einfach registrieren und loslegen!',
        keywords: 'Palladio Registrierung, B2B-Leads Konto erstellen, Geschäftsdaten Zugang, Lead-Generierung starten, Palladio Account anlegen',
        ogTitle: 'Registrieren - Werden Sie Teil von Palladio',
        ogDescription: 'Registrieren Sie sich bei Palladio und profitieren Sie von unserer umfassenden Datenbank mit deutschen Geschäftsdaten und B2B-Leads.'
    }
};





