import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InteliRouteService {
  private last_route = ""

  constructor(private router: Router) {

  }

  public store_current_route(): void {
    this.last_route = this.router.url;
  }

  navigateToStoredRoute(): void {
    if (this.last_route) {
      this.router.navigateByUrl(this.last_route);
      this.last_route = null;
    } else {
      console.warn('No route stored.');
    }
  }

  public store_next_route(next_route:string): void {
    this.last_route = next_route;
  }

  public get_last_route(last_route: string): string {
    return this.last_route;
  }

  public is_valid(): boolean {
    return this.last_route != null && this.last_route != '';
  }
}
