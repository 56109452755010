import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testemonial.component.html',
  styleUrl: './testemonial.component.scss'
})
export class TestemonialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  getStars(rating: number): string[] {
    return Array.from({ length: 5 }, (_, i) => (i < rating ? 'yellow' : ''));
  }


  users = [
    {
      testimonial: 'Die fertigen E-Mail-Listen haben mir geholfen, meine Zielgruppe schnell und gezielt zu erreichen. Sehr empfehlenswert für jeden, der im Marketing tätig ist.',
      name: 'Max Müller',
      role: 'Marketing Manager',
      rating: 5,
    },
    {
      testimonial: 'Die Listen sind gut, aber der Preis könnte etwas günstiger sein. Ansonsten sehr praktisch für schnelle Kampagnen.',
      name: 'Anna Schmidt',
      role: 'Social Media Managerin',
      rating: 4,
    },
    {
      testimonial: 'Ich habe die Liste für meine letzte Marketingaktion verwendet und konnte sofort die richtigen Kontakte ansprechen. Die Datenqualität ist hervorragend.',
      name: 'Peter Meier',
      role: 'Unternehmer',
      rating: 5,
    },
    {
      testimonial: 'Die E-Mail-Liste ist gut strukturiert, aber ich hätte mir eine genauere Segmentierung gewünscht. Insgesamt aber ein nützliches Tool.',
      name: 'Lisa Wagner',
      role: 'Verkaufsleiterin',
      rating: 4,
    },
    {
      testimonial: 'Sehr hilfreich, um schnell eine Zielgruppe anzusprechen. Einfach zu verwenden und liefert gute Ergebnisse für mein Geschäft.',
      name: 'Tom Braun',
      role: 'E-Commerce-Manager',
      rating: 5,
    },
  ];



  testimonialCarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: true,
    dots: false,
    navText: ['<img src="assets/images/Own/left.png">', '<img src="assets/images/Own/right.png">'],
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true
  }



}
