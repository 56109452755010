import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environments';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContactSendService {

    private apiUrlNewContact = `${environment.apiUrl}/add-new-contact`;


    constructor(private http: HttpClient) {

    }

    public send_new_contact(contact_json): Observable<any> {
        return this.http.post<any>(this.apiUrlNewContact, contact_json).pipe(
            tap(response => {
                return true
            }),
            catchError(error => {
                console.error('Login error', error);
                throw error;
            })
        );
    }
}
