import { EnterpriceSassWorkConfig } from '../../enterprice-sass-work.config';
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import {ScrapingRequest} from '../../../../CustomComponents/Objects/SearchResult';
import {Router} from '@angular/router';
import {CheckoutService} from '../../../../Services/BuissnessLogic/checkout.service';
import {AuthService} from '../../../../Services/BuissnessLogic/auth.service';
import {InteliRouteService} from '../../../../Services/inteli-route.service';

@Component({
  selector: 'app-enterprice-sass-work',
  templateUrl: './enterprice-sass-work.component.html',
  styleUrls: ['./enterprice-sass-work.component.scss']
})
export class EnterpriceSassWorkComponent implements OnInit, AfterViewInit {
  public showTemplateError1: boolean = false;
  public showTemplateError2: boolean = false;
  public showTemplateError3: boolean = false;
  public showTemplateError4: boolean = false;

  public selectedIndustry: string = "";
  public industryUserNote: string = "";

  public activeTab: string = "";

  /* Quantity Selector */
  selectedRowCount: number = 1000;
  selectedPaymentIndex: number | null = null;

  content: { title: string; template: TemplateRef<any> }[] = [];

  @ViewChild('template1', { static: true }) template1!: TemplateRef<any>;
  @ViewChild('template2', { static: true }) template2!: TemplateRef<any>;
  @ViewChild('template3', { static: true }) template3!: TemplateRef<any>;
  @ViewChild('template4', { static: true }) template4!: TemplateRef<any>;
  @ViewChild('template5', { static: true }) template5!: TemplateRef<any>;

  nav = EnterpriceSassWorkConfig.NAV_ITEMS;
  pricingCarouselOptions = EnterpriceSassWorkConfig.PRICING_CAROUSEL_OPTIONS;
  row_pricing = EnterpriceSassWorkConfig.PRICING;
  public selected_regions: string[] = [];
  public supported_regions = EnterpriceSassWorkConfig.SUPPORTED_REGIONS;

  constructor(private cdr: ChangeDetectorRef,
              private router: Router,
              private checkout: CheckoutService,
              private auth: AuthService,
              private intRoute: InteliRouteService
  ) {}

  ngOnInit(): void {
    this.activeTab = this.nav[0].title;
  }

  ngAfterViewInit(): void {
    this.content = [
      { title: 'Branche Auswählen', template: this.template1 },
      { title: 'Region Auswählen', template: this.template2 },
      { title: 'Spalten Bearbeiten', template: this.template3 },
      { title: 'Zeilen Bearbeiten', template: this.template4 },
      { title: 'Bestellen', template: this.template5 },
    ];
    this.cdr.detectChanges();
  }

  onSliderChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.selectedRowCount = parseInt(inputElement.value, 10);
  }

  openNewIndex(index: number) {
    if (index <= this.checkTab()) {
      this.activeTab = this.nav[index].title;
    }
  }

  openWorkSlide(title: string): void {
    const new_index = this.nav.findIndex(item => item.title === title);
    this.openNewIndex(new_index);
  }

  checkTab(): number {
    let index: number = 0;

    if (this.selectedIndustry === "") {
      this.showTemplateError1 = true;
      return 0;
    } else {
      this.showTemplateError1 = false;
    }

    if (this.selected_regions.length == 0) {
      this.showTemplateError2 = true;
      return 1;
    } else {
      this.showTemplateError2 = false;
    }

    if (this.selectedPaymentIndex === null) {
      this.showTemplateError3 = true;
      return 2;
    } else {
      this.showTemplateError3 = false;
    }

    return 5;
  }



  /* Region Selector */
  onRegionCheckboxChange(event: any, region: string) {
    // FIXME Add selecting and de Selecting of Deutschlandweit
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      this.selected_regions.push(region);
    } else {
      this.selected_regions = this.selected_regions.filter(r => r !== region);
    }
  }

  goToCheckOut() {

    let scrapingRequest: ScrapingRequest = {
      industry: this.selectedIndustry,
      region: this.getSelectedRegionFormat(),
      row_count: this.selectedRowCount,
      note: this.industryUserNote,
      price: this.calculatePrice(),
      type: this.selectedPaymentIndex
    };
    this.checkout.set_scraping_request(scrapingRequest);

    if (this.auth.isLoggedIn()) {
      this.router.navigate(["/checkout/new_table"]);
    } else {
      // set inteli Route here
      this.intRoute.store_next_route("/checkout/new_table");
      this.router.navigate(["/anmelden"]);
    }
  }

  onFeatureClick(index: number): void {
    this.selectedPaymentIndex = index;
  }

  calculatePrice(): number | null{
    if (this.selectedPaymentIndex === null) {
      return null;
    }
    const row_price:number = this.row_pricing[this.selectedPaymentIndex].price
    return row_price * this.selectedRowCount;
  }

  getSelectedRegionFormat():string {
    if (this.selected_regions.length === 0) {
      return ""
    }
    return this.selected_regions.join(', ');
  }

  clickNext(): void {
    this.openNewIndex(this.nav.findIndex(item => item.title === this.activeTab) + 1);
  }

  clickPrevious(): void {
    this.openNewIndex(this.nav.findIndex(item => item.title === this.activeTab) - 1);
  }

  isLastSlide(): boolean {
    return this.nav.findIndex(item => item.title === this.activeTab) === this.nav.length - 1;
  }

  isFirstSlide(): boolean {
    return this.nav.findIndex(item => item.title === this.activeTab) === 0;
  }
}
