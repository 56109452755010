import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SearchResult} from '../../../CustomComponents/Objects/SearchResult';
import {SearchService} from '../../../Services/BuissnessLogic/search.service';
import {ColorScssService} from '../../../shared/service/color-scss.service';
import {SeoService} from '../../../Services/SEO/seo.service';

@Component({
    selector: 'app-ScrollView',
    templateUrl: './scroll-view.html',
    styleUrls: ['./scroll-view.scss']
})
// todo fetch all, sort locally? -> improving server performance
export class ScrollView implements OnInit {
    fetched_search_results: SearchResult[] = [];
    displayedSearchResults: SearchResult[] = [];
    currentPage = 1;
    itemsPerPage = 10;
    industries: Set<string> = new Set<string>();

    public selected_regions: any[] = [];
    public supported_regions: any[] = [
        'Deutschlandweit',
        'Baden-Württemberg',
        'Bayern',
        'Berlin',
        'Brandenburg',
        'Bremen',
        'Hamburg',
        'Hessen',
        'Mecklenburg-Vorpommern',
        'Niedersachsen',
        'Nordrhein-Westfalen',
        'Rheinland-Pfalz',
        'Saarland',
        'Sachsen',
        'Sachsen-Anhalt',
        'Schleswig-Holstein',
        'Thüringen'
    ];

    public isEmpty: boolean = true;

    constructor(
        private searchService: SearchService,
        private color: ColorScssService,
        private seoService: SeoService,
    ) {
        color.resetColorScheme()
    }

    ngOnInit() {
        this.seoService.setMetaData("scrollViewPage");
        this.fetchSearchResults();
    }

    changePage(page: number): void {
        this.currentPage = page;
        this.updateDisplayedResults();
    }

    nextPage(): void {
        if (this.currentPage < this.totalPages()) {
            this.currentPage++;
            this.updateDisplayedResults();
        }
    }

    previousPage(): void {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.updateDisplayedResults();
        }
    }

    updateDisplayedResults(): void {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        this.displayedSearchResults = this.fetched_search_results.slice(startIndex, endIndex);
    }

    totalPages(): number {
        return Math.ceil(this.fetched_search_results.length / this.itemsPerPage);
    }

    fetchSearchResults() {
        this.searchService.fetchSearchResults(this.selected_regions).subscribe(
            (response) => {
                this.fetched_search_results = response.data;
                this.updateDisplayedResults();
            },
            (error) => {
                console.error('Error retrieving search results:', error);
            }
        );
    }

    onSearch(event: Event): void {
        const input = (event.target as HTMLInputElement).value.toLowerCase();

        this.displayedSearchResults = this.fetched_search_results.filter(result =>
            result.industry.toLowerCase().includes(input)
        );
    }

    /* Legacy Code */
    public updateTagFilters(tags: any[]) {
        this.selected_regions = tags;
        this.fetchSearchResults();
    }

    // Method to handle checkbox changes
    onRegionCheckboxChange(event: any, region: string) {
        // FIXME Add me back on added # of Lines to Buy
        return;
        if (event.target.checked) {
            this.selected_regions.push(region);
        } else {
            // If checkbox is unchecked, remove the region from selectedRegions
            this.selected_regions = this.selected_regions.filter(r => r !== region);
        }

        // Trigger your update function with the selected regions
        this.updateTagFilters(this.selected_regions);
    }

    onDropdownValueChangedIndustry(selectedValue: string) {
        // Apply the filter to get only results where the industry matches the selected value, case-insensitive
        const filteredResults = this.displayedSearchResults.filter(result =>
            result.industry && result.industry.toLowerCase() === selectedValue.toLowerCase()
        );
        this.displayedSearchResults = filteredResults;
    }
}
