import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environments';
import {Observable} from 'rxjs';
import {SearchResult, TableRow} from '../../CustomComponents/Objects/SearchResult';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private api_get_search_results = `${environment.apiUrl}/search-results`;
  private api_get_table_info = `${environment.apiUrl}/get-table-info`;
  private api_get_table_first_columns = `${environment.apiUrl}/get-table-first-columns`;


  constructor(private http: HttpClient) {}

  fetchSearchResults(regions: string[]): Observable<{ message: string, data: SearchResult[] }> {
    const payload = {
      regions
    };
    return this.http.post<{ message: string, data: SearchResult[] }>(this.api_get_search_results, payload);
  }

  getTableInfos(tableName: string): Observable<{ message: string, data: SearchResult }> {
    const payload = {
      table_name: tableName
    };
    return this.http.post<{ message: string, data: SearchResult }>(this.api_get_table_info, payload);
  }

  getTableColumns(tableName: string): Observable<{ message: string, data: TableRow[] }> {
    const payload = {
      table_name: tableName
    };
    return this.http.post<{ message: string, data: TableRow[] }>(this.api_get_table_first_columns, payload);
  }

}
