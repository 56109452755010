import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environments';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private userIdSubject = new BehaviorSubject<number | null>(null);
    private apiUrlLogin = `${environment.apiUrl}/login`;
    private apiUrlRegister = `${environment.apiUrl}/register`;
    private staticUserId: number = 1;

    userId$: Observable<number | null> = this.userIdSubject.asObservable();
    user_name: string = '';
    email: string = '';

    constructor(
        private http: HttpClient,
    ) {
        this.loadUserFromSession();
        // static for Testing ONLY
        // this.setStaticUserId()
    }

    login(username: string, password: string): Observable<any> {
        const user = {username, password};
        return this.http.post<any>(this.apiUrlLogin, user).pipe(
            tap(response => {
                if (response && response.user_id !== undefined) {
                    this.userIdSubject.next(response.user_id);
                    this.user_name = response.username;
                    this.email = response.email;

                    sessionStorage.setItem('userId', response.user_id.toString());
                    sessionStorage.setItem('user_name', response.username);
                    sessionStorage.setItem('email', response.email);
                }
            }),
            catchError(error => {
                console.error('Login error', error);
                throw error;
            })
        );
    }

    register(username: string, email: string, password: string,): Observable<any> {
        const user_data = {username, password, email};
        return this.http.post<any>(this.apiUrlRegister, user_data).pipe(
            tap(response => {
                if (response && response.user_id) {
                    this.userIdSubject.next(response.user_id);
                    this.email = response.email;
                    this.user_name = username;

                    sessionStorage.setItem('userId', response.user_id.toString());
                    sessionStorage.setItem('user_name', username);
                    sessionStorage.setItem('email', email);
                }
            }),
            catchError(error => {
                console.error('Register error', error);
                throw error;
            })
        );
    }

    isLoggedIn(): boolean {
        return this.getUserId() != 0;
    }

    getUserId(): number {
        if (this.userIdSubject.value == null) {
            return 0;
        } else {
            return this.userIdSubject.value;
        }
    }

    logout(): void {
        // clear variables
        this.userIdSubject.next(null);
        this.user_name = '';
        this.email = '';
        // Clear sessionStorage
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('user_name');
        sessionStorage.removeItem('email');
    }

    // Method to manually set static user ID (for testing)
    private setStaticUserId(): void {
        this.userIdSubject.next(this.staticUserId);
    }

    private loadUserFromSession(): void {
        const userId = sessionStorage.getItem('userId');
        const userName = sessionStorage.getItem('user_name');
        const email = sessionStorage.getItem('email');
        if (userId) {
            this.userIdSubject.next(+userId);
            this.user_name = userName || '';
            this.email = email || '';
        }
    }
}
