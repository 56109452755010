import { Injectable } from '@angular/core';
import {environment} from '../environments/environments';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private apiUrlFiles = environment.apiUrl + '/download-table';
  constructor(private http: HttpClient,
  ) {
  }

  download_file(user_id: number, file_name: string, file_type: string): Observable<Blob> {
    const payload = {
      user_id: user_id,
      file_name: file_name,
      file_type: file_type,
    };

    return this.http.post<Blob>(this.apiUrlFiles, payload, {
      responseType: 'blob' as 'json'
    }).pipe(
        catchError((error: any) => {
          console.error('Error in POST request:', error);
          return throwError(error);
        })
    );
  }
}
